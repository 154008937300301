import { Scan } from '../types/issues';

const DOMAIN_REGEX =
  /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/i;
export const isDomainNameValid = (domainName: string) =>
  DOMAIN_REGEX.test(domainName);

export const removeSlashAtUrlEnd = (url: string): string => {
  const lastCharacter = url.charAt(url.length - 1);
  const formattedUrl =
    lastCharacter === '/' && url.length > 1
      ? url.substring(0, url.length - 1)
      : url;
  return formattedUrl;
};

export const removeLeadingSlash = (str: string): string => {
  return str.replace(/^\//, '');
};

export const getPageName = (
  currentScan: Scan,
  searchParams?: URLSearchParams,
): string => {
  if (!currentScan) return '';
  if (searchParams) {
    return `${searchParams.get('component')} Results`;
  }

  const { url, domain } = currentScan;
  const urlWithoutTrailingSlash = (url || domain).endsWith('/')
    ? url.slice(0, -1)
    : url;
  let pageName = '';
  const urlPathList = urlWithoutTrailingSlash.split('/');
  if (urlPathList.length > 1) {
    pageName = `${urlPathList[urlPathList.length - 1].replace(
      /[-_]/g,
      ' ',
    )} Page`;
  } else {
    pageName = 'Main Page';
  }
  return pageName;
};
