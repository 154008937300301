import { parseJwt, AuthUtils, cognitoDir } from '@equally-ai/auth-service';
import { User } from 'firebase/auth';
import React, { createContext, useContext, useEffect, useReducer } from 'react';
import {
  getCurrentUserToken,
  verifyUserIsAdmin,
  LoginWithEmailAndPassword,
} from 'utils/auth';
import { AuthAction, AuthActionTypes, AuthState } from './admin-context/types';
import { useToaster } from './toast-context';
import UserApi from '@equally-ai-front/common/src/api/users';
import { useSearchParams } from 'react-router-dom';

export const authReducerInitialState: AuthState = {
  isAuthenticated: false,
  user: null,
  isAdmin: false,
  isLoading: true,
  currentUser: null,
};

export const createReducer = (
  state: AuthState,
  action: AuthAction,
): AuthState => {
  const { type, payload } = action;

  switch (type) {
    case AuthActionTypes.AUTHENTICATE:
      return {
        ...state,
        isAuthenticated: payload.isAuthenticated,
        user: payload.user,
      };

    case AuthActionTypes.USER_DETAIL:
      return {
        ...state,
        user: payload.user,
      };

    case AuthActionTypes.IS_ADMIN_UPDATED:
      return {
        ...state,
        isAdmin: payload.isAdmin,
        isLoading: false,
      };

    case AuthActionTypes.SET_LOADING:
      return {
        ...state,
        isLoading: payload.isLoading,
      };

    case AuthActionTypes.SET_ADMIN_PROFILE_INFO:
      return {
        ...state,
        currentUser: payload,
      };
    case AuthActionTypes.LOG_OUT:
      return {
        ...state,
        ...payload,
      };

    default:
      return state;
  }
};

interface UserInfo extends User {
  isAdmin?: boolean;
  accessLevel?: string;
}

interface AdminContextType {
  user: any;
  logIn: (payload: LoginWithEmailAndPassword) => void;
  logOut: () => void;
  isAdmin: boolean;
  isLoading: boolean;
  isAuthenticated: boolean;
  verifyAuthentication: () => void;
  currentUser: any;
}

type LoginType = 'google' | 'email-and-password';

const initialUser: any | null = null;

export interface VerifyAdmin {
  type: 'success' | 'error';
  payload: {
    isAdmin: boolean;
    accessLevel: string;
  } | null;
  message?: string;
}

interface AdminProviderProps {
  children: any;
}

const AuthContext = createContext<AdminContextType>({
  user: initialUser,
  logIn: () => {},
  logOut: () => {},
  isAdmin: false,
  isLoading: false,
  isAuthenticated: false,
  verifyAuthentication: () => {},
  currentUser: null,
});

export const useAdminAuth = () => useContext(AuthContext);

export const AdminProvider = ({ children }: AdminProviderProps) => {
  const [state, dispatch] = useReducer(createReducer, authReducerInitialState);
  const { isAuthenticated, user, isAdmin, isLoading, currentUser } = state;
  const { setToaster } = useToaster();
  const [searchParams, setSearchParams] = useSearchParams();

  const removeParam = (paramName: string) => {
    const newSearchParams = new URLSearchParams(searchParams);

    newSearchParams.delete(paramName);

    setSearchParams(newSearchParams);
  };

  useEffect(() => {
    void verifyAuthentication();
  }, []);
  const logIn = async (payload: LoginWithEmailAndPassword) => {
    let user;
    let success = false;
    let accessToken;

    try {
      const response = await AuthUtils.onLoginWithEmail(
        payload.email,
        payload.password,
      );

      if (!response) {
        return;
      }

      accessToken = response.idToken;
      user = response.user;
      success = true;
    } catch (error) {
      success = false;
      setToaster(
        'error',
        // @ts-ignore
        error?.message ?? AuthUtils.getErrorMessage(error?.code || ''),
      );
    }

    if (accessToken) {
      localStorage.setItem('accessToken', accessToken);
    }
    dispatch({
      type: AuthActionTypes.AUTHENTICATE,
      payload: {
        isAuthenticated: success,
        user: user,
      },
    });
  };

  const getCurentUser = async () => {
    try {
      const { data } = await UserApi.getPersonalDetails();

      if (data.code === 200) {
        dispatch({
          type: AuthActionTypes.SET_ADMIN_PROFILE_INFO,
          payload: data.message,
        });

        dispatch({
          type: AuthActionTypes.USER_DETAIL,
          payload: { user: { name: data.message.name } },
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const verifyAuthentication = async () => {
    const token = localStorage.getItem('accessToken');

    if (!token) {
      logOut();
      return;
    }

    const user: User = parseJwt(token);
    dispatch({
      type: AuthActionTypes.AUTHENTICATE,
      payload: { isLoading: true, isAuthenticated: true, user: user },
    });
    getCurentUser();
  };

  const logOut = () => {
    localStorage.removeItem('accessToken');
    AuthUtils.logout();
    dispatch({
      type: AuthActionTypes.LOG_OUT,
      payload: {
        isAuthenticated: false,
        user: null,
        isAdmin: false,
        isLoading: false,
      },
    });
  };

  const checkIfUserIsAdmin = async () => {
    const token = getCurrentUserToken();

    if (!token) {
      logOut();
      return;
    }

    const response = await verifyUserIsAdmin();
    if (response.type === 'success') {
      dispatch({
        type: AuthActionTypes.IS_ADMIN_UPDATED,
        payload: {
          isAdmin: response.payload?.isAdmin,
          accessLevel: response.payload?.accessLevel,
        },
      });
    } else if (response.type === 'error') {
      setToaster('error', response.message ?? 'User is not an admin');
      logOut();
    }
  };

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }
    void checkIfUserIsAdmin();
  }, [isAuthenticated]);

  useEffect(() => {
    const code = searchParams.get('code');
    if (!code) {
      return;
    }
    removeParam('code');

    const updateSuccessLogin = async () => {
      const { data, isSuccess } =
        await cognitoDir.getTokensFromGoogleCode(code);

      if (!data || !isSuccess) {
        setToaster('error', 'Unable to login');
        return;
      }

      const { accessToken, idToken, refreshToken } = data;
      await cognitoDir.createUserSignInSession(
        idToken,
        accessToken,
        refreshToken,
      );
      localStorage.setItem('accessToken', idToken);

      dispatch({
        type: AuthActionTypes.AUTHENTICATE,
        payload: {
          isAuthenticated: true,
        },
      });
      getCurentUser();
    };

    void updateSuccessLogin();
  }, [searchParams]);

  return (
    <AuthContext.Provider
      value={{
        verifyAuthentication,
        isLoading,
        user,
        logIn,
        logOut,
        isAdmin,
        isAuthenticated,
        currentUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
