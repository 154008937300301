import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import { WebsitesTable } from './components/websites-table';
import { Audit } from '../../components/audit';

export const WebsitesContainer = () => {
  return (
    <Routes>
      <Route index element={<WebsitesTable />} />
      <Route path={'audit'} element={<Audit />} />
    </Routes>
  );
};
