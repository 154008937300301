import { ProtectedRoute } from 'components/protected-route';
import { UnProtectedRoute } from 'components/un-protected-route';
import { LoginPage } from 'containers/auth-page/login-page';
import { DomainsPage } from 'containers/domains-page/domains-page';
import { NotAuthenticatedPage } from 'containers/not-authenticated-page/not-authenticated-page';
import { NotFoundPage } from 'containers/not-found-page/not-found';
import React from 'react';
import { AdminRedirect } from './admin-redirect';
import { UsersPage } from 'containers/users-page/users-page';
import { CouponsPage } from 'containers/coupons-page/coupons-page';
import { DomainDetailPage } from 'containers/domain-detail-page/domain-detail-page';
import { BusinessesPage } from 'containers/businesses-page/businesses-page';
import { SubscriptionsAndPlansPage } from 'containers/subscriptions-page/subscription-page';

export const ROUTES_URLS = {
  HOME_PAGE: '/*',
  DOMAINS_PAGE: '/domains',
  USERS_PAGE: '/users',
  COUPONS_PAGE: '/coupons',
  BUSINESSES_PAGE: '/businesses',
  SUBSCRIPTIONS: '/subscriptions',
  LOGIN: '/login',
  NOT_AUTHENTICATED: '/not-authenticated',
  NOT_FOUND: '*',
};

export const DOMAIN_DETAIL_URLS = {
  GENERAL: '/domains/:domainId?/*',
  PAYMENT_HISTORY: '/domains/:domainId/payment-history',
  SCAN_RECOMMENDATIONS: '/domains/:domainId/scan-recommendations',
  WIDGET_CONFIGURATION: {
    NAVIGATION: '/domains/:domainId/widget-config/navigation',
    METADATA: '/domains/:domainId/widget-config/metadata',
    LANDMARKS: '/domains/:domainId/widget-config/landmarks',
    MORE_ROLES: '/domains/:domainId/widget-config/roles',
    RELATIONSHIPS: '/domains/:domainId/widget-config/relationships',
    DYNAMIC_ELEMENTS: '/domains/:domainId/widget-config/dynamic-elements',
    CUSTOM_ATTRIBUTES: '/domains/:domainId/widget-config/custom-attributes',
  },
};

export const MENU_ITEMS = [
  {
    key: '1',
    id: 'domains-page',
    label: 'Domains',
    to: ROUTES_URLS.DOMAINS_PAGE,
  },
  {
    key: '2',
    id: 'users-page',
    label: 'Users',
    to: ROUTES_URLS.USERS_PAGE,
  },
  {
    key: '3',
    id: 'coupons-page',
    label: 'Coupons',
    to: ROUTES_URLS.COUPONS_PAGE,
  },
  {
    key: '4',
    id: 'businesses-page',
    label: 'Businesses',
    to: ROUTES_URLS.BUSINESSES_PAGE,
  },
  {
    key: '5',
    id: 'subscription-page',
    label: 'Subscriptions',
    to: ROUTES_URLS.SUBSCRIPTIONS,
  },
];

export const routes = [
  {
    path: ROUTES_URLS.HOME_PAGE,
    element: (
      <ProtectedRoute>
        <AdminRedirect />
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTES_URLS.DOMAINS_PAGE,
    element: (
      <ProtectedRoute>
        <DomainsPage />
      </ProtectedRoute>
    ),
  },
  {
    path: DOMAIN_DETAIL_URLS.GENERAL,
    element: (
      <ProtectedRoute>
        <DomainDetailPage />
      </ProtectedRoute>
    ),
  },
  // {
  //   path: DOMAIN_DETAIL_URLS.WIDGET_CONFIGURATION.NAVIGATION,
  //   element: (
  //     <ProtectedRoute>
  //       <DomainDetailPage  />
  //     </ProtectedRoute>
  //   ),
  // },
  // {
  //   path: DOMAIN_DETAIL_URLS.WIDGET_CONFIGURATION.METADATA,
  //   element: (
  //     <ProtectedRoute>
  //       <DomainDetailPage  />
  //     </ProtectedRoute>
  //   ),
  // },
  // {
  //   path: DOMAIN_DETAIL_URLS.WIDGET_CONFIGURATION.LANDMARKS,
  //   element: (
  //     <ProtectedRoute>
  //       <DomainDetailPage  />
  //     </ProtectedRoute>
  //   ),
  // },
  // {
  //   path: DOMAIN_DETAIL_URLS.WIDGET_CONFIGURATION.MORE_ROLES,
  //   element: (
  //     <ProtectedRoute>
  //       <DomainDetailPage  />
  //     </ProtectedRoute>
  //   ),
  // },
  // {
  //   path: DOMAIN_DETAIL_URLS.WIDGET_CONFIGURATION.RELATIONSHIPS,
  //   element: (
  //     <ProtectedRoute>
  //       <DomainDetailPage  />
  //     </ProtectedRoute>
  //   ),
  // },
  // {
  //   path: DOMAIN_DETAIL_URLS.WIDGET_CONFIGURATION.DYNAMIC_ELEMENTS,
  //   element: (
  //     <ProtectedRoute>
  //       <DomainDetailPage  />
  //     </ProtectedRoute>
  //   ),
  // },
  // {
  //   path: DOMAIN_DETAIL_URLS.WIDGET_CONFIGURATION.CUSTOM_ATTRIBUTES,
  //   element: (
  //     <ProtectedRoute>
  //       <DomainDetailPage  />
  //     </ProtectedRoute>
  //   ),
  // },
  {
    path: ROUTES_URLS.USERS_PAGE,
    element: (
      <ProtectedRoute>
        <UsersPage />
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTES_URLS.COUPONS_PAGE,
    element: (
      <ProtectedRoute>
        <CouponsPage />
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTES_URLS.BUSINESSES_PAGE,
    element: (
      <ProtectedRoute>
        <BusinessesPage />
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTES_URLS.SUBSCRIPTIONS,
    element: (
      <ProtectedRoute>
        <SubscriptionsAndPlansPage />
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTES_URLS.LOGIN,
    element: (
      <UnProtectedRoute>
        <LoginPage />
      </UnProtectedRoute>
    ),
  },
  {
    path: ROUTES_URLS.NOT_AUTHENTICATED,
    element: (
      <UnProtectedRoute>
        <NotAuthenticatedPage />
      </UnProtectedRoute>
    ),
  },
  {
    path: ROUTES_URLS.NOT_FOUND,
    element: <NotFoundPage />,
  },
];
