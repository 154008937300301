import { ThemeProvider } from '@mui/material/styles';
import { App } from 'containers/app';
import { AdminProvider } from 'contexts/admin-context';
import { ToastProvider } from 'contexts/toast-context';
import { CustomToastContainer } from 'components/custom-toast-container';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { store } from 'store';
import { interceptRequestsAndResponses } from 'utils/auth';
import { theme } from 'utils/theme';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

interceptRequestsAndResponses();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <Provider store={store}>
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <ToastProvider>
            <AdminProvider>
              <App />
              <CustomToastContainer />
            </AdminProvider>
          </ToastProvider>
        </ThemeProvider>
      </BrowserRouter>
    </LocalizationProvider>
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
